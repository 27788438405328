<template>
  <div>
    <h2 class="ui-kit__heading" v-text="'Отправленные ПИН-коды'" />
    <ItemCard v-for="pin in pinList" :key="pin.pin">
      <v-layout wrap align-center fill-height>
        <v-flex xs12 sm3>{{ pin.pin }}</v-flex>
        <v-flex xs12 sm2>{{ pin.fio }}</v-flex>
        <v-flex xs12 sm2>{{ pin.phone }}</v-flex>
        <v-flex xs12 sm2>{{ pin.email }}</v-flex>
        <v-flex xs12 sm3 class="text-right">{{ pointsList[pin.point - 1].point.text }}</v-flex>
      </v-layout>
      <custom-button
        slot="buttons"
        :disabled="isDisabledPinDelete"
        :loading="deletedPin === pin.pin"
        :color="isDisabledPinDelete ? 'grey' : 'primary'"
        flat
        class="my-0"
        @click="$emit('delete', pin.pin)"
      >
        <icon name="deleteIcon" size="20" />
      </custom-button>
    </ItemCard>
  </div>
</template>

<script>
import ItemCard from 'common/components/Orders/ItemCard';
export default {
  name: 'ShipperDocumentsPinList',
  components: {
    ItemCard
  },
  props: {
    isDisabledPinDelete: Boolean,
    deletedPin: {
      type: String,
      default: ''
    },
    pinList: {
      type: Array,
      default: () => []
    },
    pointsList: {
      type: Array,
      default: () => []
    }
  }
};
</script>
